<template>
	<router-view></router-view>
</template>

<script>

export default {
	name: 'App'
}
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:500');
</style>