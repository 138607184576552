<template>
	<div>
		<div class="rp-helpcenter-wrapper" v-if="flags.loading">
			<div class="lds-ellipsis">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
		<ErrorMessage v-if="flags.noConnectorPresent" />
	</div>
</template>

<script>
import { GET_CONFIG } from "../helper/constant";
import { setCookie, getCookie } from "../helper/utils";
import axios from 'axios';
import ErrorMessage from '@/components/ErrorMessage'
const domain = ()=> {
	const url = document.location.hostname;
	return url.replace('http://','').replace('https://','').replace('www.','').split(/[/?#]/)[0];
}
export default {
	name: 'Messenger',
	beforeRouteEnter (to, from, next) {
		let domainData = domain()
		if(domainData === 'localhost' || domainData === 'localhost.customerdesk.io') domainData = 'richpaneldev.dev.customerdesk.io'
		const env = domainData.includes('.dev.customerdesk.io') ? 'development' : 'production';
		const config = GET_CONFIG(env);
		const queryParams = to.query;
		axios.get(`${config.CONNECTORS_URL_BY_BRAND}${domainData}`)
        .then(response => {
			if(!response || !response.data || !response.data.status || !(Object.keys(response.data.brandData).length)){
				next(false);
			}
			let brandData = response.data.brandData;
			let connectorsData = response.data.connectorsData;
			if(!brandData || !brandData.tenantId) {
				next(vm=>{
					vm.$set(vm.flags, 'loading', false);
					vm.$set(vm.flags, 'noConnectorPresent', true);
				});
			} else {
				if(!connectorsData || !connectorsData.length){				
					next();
				}
				connectorsData = connectorsData.filter(({appClientId}) => appClientId !== brandData.tenantId+'0')
				if(!connectorsData.length){
					next();
				}
				next(vm => {
					if(!connectorsData.length) {
						vm.$set(vm.flags, 'loading', false);
					}
					vm.$set(vm.flags, 'noConnectorPresent', !connectorsData.length);
					if(brandData.favicon && brandData.favicon !== 'NA') {
						vm.changeFavicon("https://s3-us-west-2.amazonaws.com/richpanel-assets/"+brandData.favicon)
					}
					else if(brandData.brandIcon && brandData.brandIcon !== 'NA') {
						vm.changeFavicon("https://s3-us-west-2.amazonaws.com/richpanel-assets/"+brandData.brandIcon)
					}
					else if(brandData && brandData.brandId && brandData.brandId == 'theproteinworks155b10') {
						vm.changeFavicon("https://s3-us-west-2.amazonaws.com/richpanel-data/MessengerAttachments/6a4b6469-a294-4cfb-8fa4-8fe0c6f7a5e5/abnormal-Device-a-B (1).png")
					}
					else if(brandData && brandData.tenantId && brandData.tenantId.includes('theproteinworks155')) {
						vm.changeFavicon("https://m.theproteinworks.com/files/favicon-16x16.png")
					}

					if(brandData.brandName) vm.changeTitleAndDescription(brandData.brandName)
					const { appClientId, helpcenterMode = "fullscreen", did, encryptedUserParams } = to.query
					if(did) {
						vm.setCookie('rpdid', did, {
							expires: 32554,
							domain: vm.domain,
							path: '/',
							secure: false
						})
					}

					if (encryptedUserParams) {
						window.richpanelSettings = encryptedUserParams
					}
					let storeToLoad = connectorsData[0];
					if (appClientId) {
						storeToLoad = connectorsData.find(({ appClientId }) => appClientId === appClientId)
					}
					const orderQueryParams = {};
					if(queryParams.orderId){
						orderQueryParams.orderId = queryParams.orderId;
					}
					if(queryParams.pincode){
						orderQueryParams.pincode = queryParams.pincode;
					}
					if(queryParams.email){
						orderQueryParams.email = queryParams.email;
					}
					if(storeToLoad){
						vm.initialize(storeToLoad.appClientId, helpcenterMode, config, orderQueryParams)
					}
					try {
						if(queryParams.code && localStorage) {
							localStorage.setItem('rpMessengerAuth', queryParams.code)
						}
					} catch(e) {
						console.error(e)
					}
				})
			
			}
			
        })
		
	},
	components: {
		ErrorMessage,
	},
	data() {
		return {
			flags: {
				loading: true,
				noConnectorPresent: false,
			},
		}
	},
	mounted() {
		try {
			let loaderElement = document.getElementById('richpanel-helpcenter-pre-loader');
			if(loaderElement) {
				loaderElement.remove();
			}
		} catch(e) {
			console.log(e)
		}
	},
	computed: {
		domain () {
			const url = document.location.hostname;
			return url.replace('http://','').replace('https://','').replace('www.','').split(/[/?#]/)[0];
		}
	},
	methods: {
		setCookie,
		getCookie,
		initialize (appClientId, helpcenterMode, config, orderQueryParams) {
			(function(d, s, id, e, u){
					var js, fjs = d.getElementsByTagName(s)[0]
					if (d.getElementById(id)) {return}
					js = d.createElement(s)
					js.id = id
					js.async=!0
					js.type="text/javascript"
					js.src = `${u}?appClientId=${e}`
					fjs.parentNode.insertBefore(js, fjs)
				}(document, 'script', 'richpanel-jssdk', appClientId, config.API_URL))
			// window.richpanel.load(appClientId);
			window.richpanel||(window.richpanel=[])
			window.richpanel.q=[]
			let mth=["track","debug","atr"]
			const sk=function(e){return function(){
				let a=Array.prototype.slice.call(arguments)
				a.unshift(e)
				window.richpanel.q.push(a)}
			}
			for(var i=0;mth.length>i;i++)
				window.richpanel[mth[i]]=sk(mth[i]);
			window.richpanel.helpcenterMode = helpcenterMode;
			window.richpanel.orderQueryParams = orderQueryParams || {};
		},

		changeFavicon(src) {
			//document.head = document.head || document.getElementsByTagName('head')[0];
			var link = document.createElement('link'),
			oldLink = document.getElementById('dynamic-favicon');
			link.id = 'dynamic-favicon';
			link.rel = 'shortcut icon';
			link.href = src;
			if (oldLink) {
				document.head.removeChild(oldLink);
			}
			document.head.appendChild(link);
		},
		changeTitleAndDescription(brandName) {
			//title
			let titleElement = document.getElementsByTagName('title')
			if(!titleElement || !titleElement[0]){
				let title = document.createElement('title')
				title.innerText = `${brandName} Help Center`
				document.head.appendChild(title);
			} else {
				titleElement[0].innerText = `${brandName} Help Center`
			}
			//description
			let desc = document.createElement('meta')
			desc.name = 'description';
			desc.content = `${brandName} - Get answers & speak to your support team here.`;
			document.head.appendChild(desc);

		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
