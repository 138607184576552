<template>
    <div class="rp-helpcenter__error-container">
			<div class="rp-helpcenter__error-title">
				We couldn't find what you were looking for!
			</div>
			<img class="rp-helpcenter__error-image" :src="require('@/assets/NotFound.svg')" />
			<div class="rp-helpcenter__error-message">
				If you are the owner, please make sure that the store is connected.
			</div>
			<div class="rp-helpcenter__richpanel">
				<img :src="require('@/assets/icons/Richpanel.svg')"  />
				<span  class="rp-helpcenter__richpanel-text">Powered by Richpanel</span>
			</div>
		</div>
</template>

<script>
export default {
    
}
</script>

<style>
.rp-helpcenter__error-container{
	font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #F9F9FA;
}
.rp-helpcenter__error-title{
	color: #1F2324;
	font-size: 36px;
	font-weight: 300;
	margin: 90px 0px 75px;
}
.rp-helpcenter__error-image{
	margin-bottom: 50px;
	width: 500px;
	height: 300px;
}
.rp-helpcenter__error-message{
	color: #666666;
	font-size: 18px;
	width: 60%;
	text-align: center;
	padding-bottom: 40px;
	border-bottom: 1px solid #e5e5e5;
}
.rp-helpcenter__richpanel{
	color: #666666;
	font-size: 13px;
	margin-top: 35px;
	font-weight: 300;
	display: flex;
	align-items: center;
}
.rp-helpcenter__richpanel-text{
	margin-left: 5px;
}

@media screen and (max-width: 900px){
	.rp-helpcenter__error-message{
		width: 80%;
	}
	.rp-helpcenter__richpanel{
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 800px){
	.rp-helpcenter__error-title{
		font-size: 28px;
	}
	.rp-helpcenter__error-image{
		width: 80%;
	}
}
@media screen and (max-width: 600px){
	.rp-helpcenter__error-title{
		font-size: 22px;
		text-align: center;
		padding: 0 20px;
		margin-bottom: 50px;
	}
	.rp-helpcenter__error-message{
		font-size: 14px;
	}
}

</style>