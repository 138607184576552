import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Messenger from './components/Messenger'
import Nothing from './components/Nothing'

Vue.use(VueRouter)

Vue.config.productionTip = false

const routes = [{
  path: '/', component: Messenger,
},{
  path: '/help', component: Messenger,
}, {
  path: '*', component: Nothing
}]

const router = new VueRouter({
  routes, // short for `routes: routes`
  mode: 'history'
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
