const utils = {
    isArray: Array.isArray || function(value) {
        return Object.prototype.toString.call(value) === '[object Array]';
    },
    isPlainObject: function(value) {
        return !!value && Object.prototype.toString.call(value) === '[object Object]';
    },
    toArray: function(value) {
        return Array.prototype.slice.call(value);
    },
    getKeys: Object.keys || function(obj) {
        var keys = [],
            key = '';
        for (key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj,key)) keys.push(key);
        }
        return keys;
    },
    encode: function(value) {
        return String(value).replace(/[,;"\\=\s%]/g, function(character) {
            return encodeURIComponent(character);
        });
    },
    decode: function(value) {
        return decodeURIComponent(value);
    },
    retrieve: function(value, fallback) {
        return value == null ? fallback : value;
    }
};

const setCookie = function(key, value, options) {
    if (utils.isPlainObject(key)) {
        for (var k in key) {
            if (Object.prototype.hasOwnProperty.call(key, k)) this.set(k, key[k], value);
        }
    } else {
        options = utils.isPlainObject(options) ? options : {
            expires: options
        };
        var expires = options.expires !== undefined ? options.expires : (this.defaults.expires || ''),
            expiresType = typeof(expires);
        if (expiresType === 'string' && expires !== '') expires = new Date(expires);
        else if (expiresType === 'number') expires = new Date(+new Date + 1000 * this.expiresMultiplier * expires);
        if (expires !== '' && 'toGMTString' in expires) expires = ';expires=' + expires.toGMTString();
        var path = options.path || this.defaults.path;
        path = path ? ';path=' + path : '';
        var domain = options.domain || this.defaults.domain;
        domain = domain ? ';domain=' + domain : '';
        var secure = options.secure || this.defaults.secure ? ';secure' : '';
        if (options.secure === false) secure = '';
        document.cookie = utils.encode(key) + '=' + utils.encode(value) + expires + path + domain + secure;
    }
    return this;
};

const getCookie = function(keys, fallback) {
    var cookies = this.all();
    if (utils.isArray(keys)) {
        var result = {};
        for (var i = 0, l = keys.length; i < l; i++) {
            var value = keys[i];
            result[value] = utils.retrieve(cookies[value], fallback);
        }
        return result;
    } else return utils.retrieve(cookies[keys], fallback);
};

module.exports = {
    getCookie,
    setCookie
}